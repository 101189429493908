










































import Vue from "vue";
import moment from "moment";
import PolizaService, { GetPolizaDto } from "@/api/poliza/PolizaService";
//import DatePicker from "@/components/shared/DatePïcker.vue";
import { GetPaginateDto } from "@/api/interfaces";

export default Vue.extend({
  components: {
    //DatePicker
  },
  data() {
    return {
      fechaInicio: new Date().toISOString().substr(0, 10),
      fechaFin: new Date().toISOString().substr(0, 10),
      dato: {},
      dialog: false,
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "# Poliza", value: "numero" },
        { text: "Fecha Emisión", value: "FechaEmision", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      polizas: {
        items: [],
        meta: {
          totalItems: 0,
          itemCount: 0,
          itemsPerPage: 10,
          totalPages: 0,
          currentPage: 1
        }
      } as GetPaginateDto<GetPolizaDto>,
      filters: {
        numero: ""
      }
    };
  },
  methods: {
    fetchData(search = false) {
      const params = new URLSearchParams({
        page: search ? "1" : this.polizas.meta.currentPage.toString(),
        limit: "10",
        ...this.filters
        //fechaInicio: this.fechaInicio,
        //fechaFin: this.fechaFin
      }).toString();
      PolizaService.getAll(params).then(response => {
        this.polizas = response;
      });
    },
    downloadExport() {
      PolizaService.getReporteCobroExcel()
        .then(response => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          const today = new Date();
          moment.locale("es");
          link.setAttribute(
            "download",
            `poliza-reporte-_${moment(today).format("YYYY-MMMM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          console.log("no se pudo generar el documento");
        });
    }

    //nuevo
  },
  created() {
    this.fetchData();
  }
});
