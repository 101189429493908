import httpClient from "../http-client";
import { GetPaginateDto } from "../interfaces";

const ENDPOINT = "/poliza";

export interface GetPolizaDto {
  id: number;
}

export interface GetCobroPolizaDto {
  id: number;
}

class PolizaService {
  getAll(query: string): Promise<GetPaginateDto<GetPolizaDto>> {
    return httpClient.get(`${ENDPOINT}?${query}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  get(id: string | number): Promise<GetPolizaDto> {
    return httpClient.get(`${ENDPOINT}/${id}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getCobros(
    id: string | number,
    query: string
  ): Promise<GetPaginateDto<GetCobroPolizaDto>> {
    return httpClient.get(`${ENDPOINT}/${id}/cobro?${query}`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  createCobro(id: string | number, dto: any) {
    return httpClient.post(`${ENDPOINT}/${id}/cobro`, dto);
  }

  getRemisoriasUnion(id: string | number): Promise<any> {
    return httpClient
      .get(`${ENDPOINT}/${id}/remisoria-union`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  getReporteCobroExcel() {
    return httpClient.get(`${ENDPOINT}/reporte-cobro-excel`, {
      responseType: "blob"
    });
  }
}
export default new PolizaService();
